.Store360-body-wrapper {
  .PivotTableComponent {
    max-height: 73vh !important;
  }
  .Store360-body-info {
    .Store360-body-info-lower {
      div {
        &:last-of-type {
          border: none !important;
        }
      }
    }
  }
  .metric-cards-wrapper {
    display: grid;
    border: 1px solid var(--divider);
    width: 100%;
    grid-template-columns: repeat(6, 1fr);
    .metric-card {
      grid-column: span 1;
      border: unset;
      &.box-1 {
        box-shadow: var(--div_rb);
      }
      &.box-2 {
        box-shadow: var(--div_rb);
      }
      &.box-3 {
        box-shadow: var(--div_rb);
      }
      &.box-4 {
        box-shadow: var(--div_rb);
      }
      &.box-5 {
        box-shadow: var(--div_rb);
      }
      &.box-6 {
        box-shadow: var(--div_b);
      }
      &.box-7 {
        box-shadow: var(--div_rb);
      }
      &.box-8 {
        box-shadow: var(--div_rb);
      }
      &.box-9 {
        box-shadow: var(--div_rb);
      }
      &.box-10 {
        box-shadow: var(--div_rb);
      }
      &.box-11 {
        box-shadow: var(--div_rb);
      }
      &.box-12 {
        box-shadow: var(--div_b);
      }
      &.box-13 {
        box-shadow: var(--div_r);
      }
      &.box-14 {
        box-shadow: var(--div_rb);
      }
      &.box-15 {
        box-shadow: var(--div_rb);
      }
      &.box-16 {
        box-shadow: var(--div_rb);
      }
      &.box-17 {
        box-shadow: var(--div_rb);
      }
      &.box-18 {
        box-shadow: var(--div_b);
      }
      &.box-19 {
        box-shadow: var(--div_r);
      }
      &.box-20 {
        box-shadow: var(--div_r);
      }
    }
  }
  .Store360-text-fade {
    color: #666666 !important;
  }
}
